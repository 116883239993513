import React, { useState } from 'react'
import { setConfig } from 'react-hot-loader'

import { format } from 'date-fns'

format(new Date(2014, 1, 11), 'MM/dd/yyyy')

setConfig({ pureSFC: true })

function Banner({ homePage }) {
  const [shown, setShown] = useState(false)

  if (typeof window === 'undefined') {
    return null
  }

  const forceBanner =
    new URLSearchParams(window.location.search).get('showBanner') !== null

  const bannerWasntHiddenBefore =
    window.sessionStorage.getItem('bannerClicked') === null

  const today = format(new Date(), 'yyyy-MM-dd')
  const bannerWasNotHiddenToday =
    window.sessionStorage.getItem('bannerClicked') !== today

  const shouldBeShown =
    forceBanner || bannerWasntHiddenBefore || bannerWasNotHiddenToday

  if (shown !== shouldBeShown) {
    setShown(shouldBeShown)
  }

  if (!shown || !homePage?.acf?.banner) {
    return null
  }

  function closeAndSaveToLocalStorage() {
    window.sessionStorage.setItem('bannerClicked', today)
    setShown(false)
  }

  return (
    <>
      <div
        className="banner-wrapper"
        onClick={() => closeAndSaveToLocalStorage()}
      >
        <p className="banner">
          <span>Wichtiger Hinweis</span>
          {homePage.acf.banner}
          <br />
          <br />
          <a onClick={() => closeAndSaveToLocalStorage()}>Hinweis schließen</a>
        </p>
      </div>
    </>
  )
}

export default Banner
